<template>
  <div class="flex flex-1 flex-col gap-2">
    <ClientOnly v-if="showSuggestionsAsTooltip">
      <UiPopoverTooltip
        ref="searchSuggestionsPopoverEl"
        placement="bottom-start"
        class="hidden w-full lg:block"
        :hover="false"
        :show-arrow="false"
        :tooltip-class="['!p-0', limitEnglishVersionSearchFunctionality ? 'invisible' : 'invisible lg:visible', tooltipClass]"
        :offset="8"
        min-width-as-parent
        :mobile-modal="false"
        @activated="handleShowSearchSuggestions"
      >
        <template #trigger>
          <!-- There are some issues with keyup/keyup.enter events in render function,
        that's why those events are used here directly -->
          <SearchInput
            @keyup.enter="handleInputSearch"
            @keyup="handleShowSearchSuggestions(handleSearchInputSelect)"
          />
        </template>

        <SearchSuggestions v-if="shouldShowSearchSuggestions" />
      </UiPopoverTooltip>

      <template #fallback>
        <SearchInput
          @keyup.enter="handleInputSearch"
          @keyup="handleShowSearchSuggestions(handleSearchInputSelect)"
        />
      </template>
    </ClientOnly>

    <template v-else>
      <div :class="props.inputContainerClass">
        <SearchInput
          @keyup.enter="handleInputSearch"
          @keyup="handleShowSearchSuggestions(handleSearchInputSelect)"
        />
        <slot name="input" />
      </div>
      <ClientOnly v-if="shouldShowSearchSuggestions">
        <SearchSuggestions />
        <slot name="suggestions" />
      </ClientOnly>
    </template>
  </div>
  <ClientOnly>
    <UiBarcodeScanner
      v-if="barcodeDetectorSupported"
      ref="uiBarcodeScannerEl"
      class="block lg:hidden"
      :labels="{ cancel: t('cancel'), msgError: t('allow_the_use_of_the_camera') }"
      @found-barcode="handleFoundBarcode"
    />
  </ClientOnly>
</template>

<script lang="ts" setup>
import UiBarcodeScanner from '@ui/components/UiBarcodeScanner/UiBarcodeScanner.vue'
import { onClickOutside } from '@vueuse/core'
import UiPopoverTooltip from '@ui/components/UiPopoverTooltip/UiPopoverTooltip.vue'
import M2SearchSuggestions from '@ecom/components/M2SearchSuggestions/M2SearchSuggestions.vue'
import { SearchRouteName, SearchUrlPart } from '@ecom/stores/search'
import { useSearchSuggestions } from '@ecom/composables/useSearchSuggestions'
import { useSearch } from '@ecom/composables/useSearch/useSearch'
import { RawlplugUiMqKey } from '@ui/plugin'
import type { RawlplugUiMq } from '@ui/plugin'
import AppSearchInput from './AppSearchInput/AppSearchInput.vue'

interface AppSearchProps {
  searchPhrase?: string
  tooltipClass?: string
  showBackButton?: boolean
  showSuggestionsAsTooltip?: boolean
  inputContainerClass?: string | string[]
  fillAvailable?: boolean
}

const props = withDefaults(defineProps<AppSearchProps>(), {
  searchPhrase: '',
  tooltipClass: '!pt-0',
  showBackButton: false,
  showSuggestionsAsTooltip: true,
  inputContainerClass: '',
  fillAvailable: false,
})

const emit = defineEmits<{
  (e: 'search'): void
  (e: 'update:search-phrase', value: string): void
  (e: 'click:back'): void
}>()

const { isDesktop } = (inject(RawlplugUiMqKey) as RawlplugUiMq) || {}

const route = useRoute()
const router = useRouter()
const { localePath } = useT3Utils()
const { currentLocale } = useT3i18n()
const { t } = useI18n()
const { currentRoute } = router
const limitEnglishVersionSearchFunctionality = currentLocale.value === 'pl-en'

const {
  searchBarSavedOptions,
} = useSearch()
const {
  searchPhraseModel,
  searchSuggesterEl,
  handleShowSearchSuggestions,
  handleSearchInputSelect,
  areSearchSuggestionsVisible,
  searchSuggestionsPopoverEl,
  closeSearchSuggestions,
} = useSearchSuggestions()

const isBarcodeScanningInProgress = ref(false)
const foundExactProduct = ref<string | undefined>()
const hasUserSubmited = ref(false)
const isSearching = ref<null | boolean>(null)

function SearchInput() {
  return h(AppSearchInput, {
    'searchPhrase': props.searchPhrase,
    'enableSearchOptions': import.meta.server ? true : isDesktop.value,
    'enableBarcodeScanner': barcodeDetectorSupported.value && !limitEnglishVersionSearchFunctionality,
    'onUpdate:searchPhrase': (value: string) => {
      searchPhraseModel.value = value
      emit('update:search-phrase', value)
    },
    'onClick': handleShowSearchSuggestions,
    'onClick:label': handleShowSearchSuggestions(handleSearchInputSelect),
    'onClick:search': handleInputSearch,
    'onClick:back': () => emit('click:back'),
    'onFocus': handleSearchInputSelect,
    'onClick:show-barcode-scanner': handleShowBarcodeScanner,
  })
}

function handleShowBarcodeScanner() {
  if (uiBarcodeScannerEl.value) {
    isBarcodeScanningInProgress.value = true
    uiBarcodeScannerEl.value.displayBarcodeScanner()
  }
}
function SearchSuggestions() {
  return h(M2SearchSuggestions, {
    'ref': searchSuggesterEl,
    'class': [{ 'max-h-[calc(100dvh-230px)]': props.fillAvailable }, 'lg:max-h-[calc(100dvh-260px)] lg:py-6'],
    'style': { minHeight: '125px' },
    'searchPhrase': searchPhraseModel.value,
    'loading': isSearching.value,
    'enableBarcodeScanner': barcodeDetectorSupported.value && !limitEnglishVersionSearchFunctionality,
    'onUpdate:loading': (value: null | boolean) => isSearching.value = value,
    'onSearch': value => handleSearch(value),
    'onSuggestions': handleLazySubmission,
    'onClose': handleCloseSearchSuggestions,
    'onExactProduct': handleLazySubmission,
  })
}

onClickOutside(searchSuggestionsPopoverEl as unknown as HTMLElement, () => {
  deactivateSuggestions()
}, {
  // #vs__listbox - UiSelect dropdown list
  ignore: [searchSuggesterEl, '#vs__listbox'],
})

watch(isSearching, async () => {
  await handleLazySubmission()
})

function handleCloseSearchSuggestions() {
  closeSearchSuggestions(() => {
    resetLazySubmissionState()
    emit('search')
  })
}

/**
 * temporary solution for pl-en version
 * remove it later
 */
function handleExternalSearch(value: string) {
  if (typeof window !== 'undefined') {
    navigateTo(`https://old.rawlplug.com/search.php?q=${value}`, {
      external: true,
    })
  }
}

function activeLazySubmissionState() {
  hasUserSubmited.value = true
}

function resetLazySubmissionState() {
  hasUserSubmited.value = false
  foundExactProduct.value = undefined
  isSearching.value = null
}

function deactivateSuggestions() {
  searchSuggestionsPopoverEl.value?.deactivate?.()
  areSearchSuggestionsVisible.value = false
}

/**
 * When the user provides the exact SKU of a product and it is found by the suggester,
 * then try to navigate directly to the product's page
 * Otherwise, proceed to the search page.
 */
async function handleLazySubmission(exactProduct?: string) {
  if (exactProduct) {
    foundExactProduct.value = exactProduct
  }

  if (!hasUserSubmited.value) {
    return
  }

  if (foundExactProduct.value) {
    await navigateTo(foundExactProduct.value)
    resetLazySubmissionState()
    emit('search')
    return
  }
  if (isSearching.value === false || limitEnglishVersionSearchFunctionality) {
    resetLazySubmissionState()
    await handleSearch(searchPhraseModel.value)
  }
}

async function handleSearch(searchTerm: string) {
  if (!searchTerm) {
    resetLazySubmissionState()
    return
  }

  if (limitEnglishVersionSearchFunctionality) {
    return handleExternalSearch(searchTerm)
  }

  let searchTab = SearchUrlPart.STORE
  const routeName = route.meta.name

  switch (routeName) {
    case SearchRouteName.CMS: {
      searchTab = SearchUrlPart.CMS
      break
    }
    default:
      searchTab = SearchUrlPart.STORE
      break
  }

  // using 'search-page' route name returns error, so path is used
  await router.push({
    path: localePath(`/search/${searchTab}`),
    query: { term: encodeURIComponent(searchTerm), option: searchBarSavedOptions.value.selectedOption.value },
  })
  emit('search')
}
const barcodeDetectorSupported = computed(() => {
  return import.meta.client ? 'BarcodeDetector' in window : false
})

const shouldShowSearchSuggestions = computed(() => !limitEnglishVersionSearchFunctionality && areSearchSuggestionsVisible.value && !isBarcodeScanningInProgress.value)

async function handleInputSearch() {
  activeLazySubmissionState()
  if (!searchPhraseModel.value) {
    return
  }

  if (areSearchSuggestionsVisible.value && !isSearching.value) {
    return await handleLazySubmission()
  }
}

const uiBarcodeScannerEl = ref<InstanceType<typeof UiBarcodeScanner> | null>(
  null,
)

async function handleFoundBarcode(barcode: string) {
  searchPhraseModel.value = barcode
  uiBarcodeScannerEl?.value?.disableBarcodeScanner()

  try {
    await handleSearch(searchPhraseModel.value)
  }
  finally {
    isBarcodeScanningInProgress.value = false
  }
}

watch(
  currentRoute,
  (newRoute, oldRoute) => {
    if (
      JSON.stringify(newRoute.fullPath)
      === JSON.stringify(oldRoute.fullPath)
    ) {
      return
    }

    if (areSearchSuggestionsVisible.value) {
      handleCloseSearchSuggestions()
    }
  },
  { deep: true },
)

defineExpose({
  areSearchSuggestionsVisible,
  handleCloseSearchSuggestions,
})
</script>
